<template>
  <div id="edit-client" style="min-height: 300px">
    <md-card>
      <md-card-content>
        <div v-if="loaded">
          <div id="clientPanelTab">
            <b-tabs class="tabs-title" v-model="tabIndex">
              <b-tab @click="onTabChange(1)" :title="msg('Profile')">
                <perfect-scrollbar style="">
                  <div style="padding: 20px" class="md-scrollbar">
                    <clientDetails v-bind:clientObj="client"/>
                  </div>
                </perfect-scrollbar>
              </b-tab>
              <b-tab :title="msg('Organizations')" class="clientOrg">
                <div style="padding: 20px 20px 0;" class="md-scrollbar">
                  <clientDetails :clientObj="client" :showClient="false" :showOrganization="true"/>
                </div>
                <div style="padding: 0 20px 20px;" class="md-scrollbar" v-if="!isNewClient">
                  <representatives v-bind:clientId="client.id" v-bind:representatives="client.client2Users" :canAdd="false" :historyMaxHeight="200"/>
                </div>
              </b-tab>

              <b-tab @click="onTabChange(4)" :title="msg('Notes')" v-if="!isNewClient && canReadNotes">
                <perfect-scrollbar style="">
                  <div style="padding: 20px" class="md-scrollbar">
                    <notes v-bind:clientId="client.id"></notes>
                  </div>
                </perfect-scrollbar>
              </b-tab>
              <b-tab @click="onTabChange(5)" :title="msg('Calls')" v-if="!isNewClient && canReadPhone && hasPhone">
                <perfect-scrollbar style="">
                  <phoneRecords :phone="client.phone"/>
                </perfect-scrollbar>
              </b-tab>
            </b-tabs>
          </div>
        </div>
        <div v-else-if="this.error != null">
          <b-tabs class="tabs-title">
            <b-tab :title="msg('Profile')">
              <div style="padding: 20px" class="md-scrollbar">
                <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i>
                  <strong>{{ error }}</strong>
                </b-alert>
              </div>
            </b-tab>
          </b-tabs>
        </div>
        <div v-else>
          <b-tabs class="tabs-title">
            <b-tab @click="onTabChange(1)" :title="msg('Profile')">
              <div style="padding: 20px" class="md-scrollbar">
                <b-spinner label="Spinning"/>
                {{ msg('loading') }}
              </div>
            </b-tab>
          </b-tabs>

        </div>
      </md-card-content>
    </md-card>

  </div>
</template>

<script>

import $ from 'jquery'
import { mapActions, mapGetters, mapState } from 'vuex'
import notes from '../../components/panels/notes/notes'
import phoneRecords from '../../components/panels/client/phoneRecords'
import representatives from '../../components/panels/client/representatives'
import clientDetails from '../../components/panels/client/clientDetails'
import { validation } from '../../global/helper'
import { required, requiredIf, email, } from 'vuelidate/lib/validators'

export default {
  name: 'editClient',
  components: {
    notes,
    phoneRecords,
    representatives,
    clientDetails
  },
  data() {
    return {
      id: 0,
      loaded: false,
      error: null,
      tabIndex: 0,
      client: {},
      timerActive: false
    }
  },
  created() {
    if (isNaN(this.$route.params.id)) {
      return this.error = 'Invalid client id'
    }

    this.id = parseInt(this.$route.params.id)
    this.onLoad()
  },
  computed: {
    ...mapGetters('translator', ['msg']),
    ...mapGetters('notes', ['canReadNotes']),
    ...mapGetters('phone', ['canReadPhone']),
    ...mapGetters('data', {
      _getAffiliateIdByBannerId: 'getAffiliateIdByBannerId',
      _getCampaignByAffId: 'getCampaignByAffId',
      _getBanners: 'getBanners'
    }),
    ...mapState({
      data: function (store) {
        return store.data
      }
    }),
    isNewClient() {
      return typeof this.id === 'number' && !isNaN(this.id) && this.id <= 0
    },
    hasAccount() {
      return this.id != null && this.id > 0 && this.loaded === true && this.client.account != null
    },
    hasPhone() {
      return this.id != null && this.id > 0 && this.loaded === true && this.client.phone != null
    },

  },
  methods: {
    ...mapActions('clients', {
      _getClient: 'get',
      _updateClient: 'update',
      _register: 'register'
    }),
    onLoad() {
      this.loaded = false
      if (isNaN(this.id) || this.id === 0) {
        return this.error = this.msg('Invalid client id')
      } else {
        this.loadClient()
      }
    },
    loadClient() {
      let $this = this
      $this.loaded = false
      this._getClient({ id: $this.id })
          .then((client) => {
            //After getting client, get affiliate details
            let aff = this._getAffiliateIdByBannerId(client.bannerid)
            //In order to the observer work, we will generate a new object with the affiliate details
            //and append the client object
            $this.client = {
              ...client,
              affId: aff.affiliateId,
              camId: aff.campaignId
            }
            //Set page as loaded
            $this.loaded = true
            console.log(aff)
          }, (error) => {
            $this.error = error
          })
    },
    onTreatmentSelected(id) {
      // this.treatmentActive = id;
      this.$router.push(`/treatment/client/${this.client.id}/session/${id}`)
    },
    onTabChange(tabIndex) {

      switch (tabIndex) {
        case 1:
          this.$root.$emit('crm::account::profile::load')
          break
        case 4:
          this.$root.$emit('crm::client::note::load')
          break
        case 5:
          this.$root.$emit('crm::client::phone::load')
          break
        case 6:
          this.$root.$emit('crm::client::monetary::load')
          break
      }
    },
    submit(e) {
      console.log('submit')
      if (typeof e === 'object' && typeof e.preventDefault === 'function') {
        e.preventDefault()
      }

      if (this.$v.$invalid) {
        return
      }

      if (!isNaN(this.client.id) && this.client.id > 0) {
        return this.updateClient()
      }
      return this.createClient()
    },
    createClient() {
      console.log('updateClient()')
      let $this = this
      this._register({ client: this.client })
          .then(
              (a) => $this.$emit('isNewClient', a),
              () => $this.$emit('isNewClient', 0)
          )
      // this.$emit("submited")
    },
    updateClient() {
      let $this = this
      this._updateClient({
        id: this.client.id,
        client: this.client
      })
          .then(
              () => $this.$emit('clientUpdated', true),
              () => $this.$emit('isNewClient', false)
          )
      // this.$emit("submited")
    },
  },
  validations: {
    client: {
      firstname: {
        required,
        name: validation.name,
      },
      lastname: {
        required,
        name: validation.name,
      },
      phone: {
        required
      },
      email: {
        required,
        email,
      },
      email2: {
        email,
      },
      camId: {
        required: requiredIf(function () {
          return this.client.affId != null && this.client.affId > 0
        })
      },
      bannerid: {
        required: requiredIf(function () {
          console.log('bannerid.requiredIf: ' + this.client.affId + ' ' + this.client.affId != null && this.client.affId > 0)
          return this.client.affId != null && this.client.affId > 0
        })
      },

    }
  }
}
</script>

<style scoped>

</style>
